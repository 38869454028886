import React, {useEffect} from 'react';
import Hero from '../components/Hero';
import { Swiper, SwiperSlide } from 'swiper/react';
import heroImg from '../assets/img/about/body.jpeg';
import Aboutimg from '../assets/img/about/cover.jpeg';
import About1 from '../assets/img/gallery/photo_1.jpeg';
import About2 from '../assets/img/gallery/photo_2.jpeg';
import About3 from '../assets/img/gallery/photo_3.jpeg';
import About4 from '../assets/img/gallery/photo_4.jpeg';
import About5 from '../assets/img/gallery/photo_5.jpeg';
import About6 from '../assets/img/gallery/photo_6.jpeg';
import About7 from '../assets/img/gallery/photo_7.jpeg';
import About8 from '../assets/img/gallery/photo_8.jpeg';
import About9 from '../assets/img/gallery/photo_9.jpeg';
import About10 from '../assets/img/gallery/photo_10.jpeg';
import About11 from '../assets/img/gallery/photo_11.jpeg';
import About12 from '../assets/img/gallery/photo_12.jpeg';
import About13 from '../assets/img/gallery/photo_13.jpeg';
import About14 from '../assets/img/gallery/photo_14.jpeg';
import About15 from '../assets/img/gallery/photo_15.jpeg';
import About16 from '../assets/img/gallery/photo_16.jpeg';
import About17 from '../assets/img/gallery/photo_17.jpeg';
import About18 from '../assets/img/gallery/photo_18.jpeg';
import axios from "axios";

const heroTitle = 'Despre noi';
const heroDescription = 'Mă veţi căuta, şi Mă veţi găsi dacă Mă veţi căuta cu toată inima. (Ieremia 29:13). La întâlnirile noastre, căutăm să descoperim mai mult din Dumnezeu. Credem ca nu cei din față sunt cei care trebuie să ți-L descopere pe Dumnezeu, ci tu Îl vei găsi dacă Îl vei căuta cu toată inima.';

function About() {

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}about_us`)
            .then(function (response) {
            })
    }, [])

    return (

        <div className="aboutpage">

            <Hero side='left' title={heroTitle} description={heroDescription} img={heroImg} />
 
            <div className="aboutpage-main">

                <div className="aboutpage-main-header wrapper">

                    <h3 className="p20">Scopul nostru</h3>

                    <h1 className="title60">Vrem să trezim o națiune de tineri care din ce în ce mai mult se depărtează de Dumnezeu.</h1>

                    <img src={Aboutimg} alt="Vrem sa trezim o natiune de tineri care din ce in ce mai mult se departeaza de Dumnezeu."/>

                </div>

                <div className="aboutpage-main-about wrapper">

                    <p>Trăim haotic și împrăștiat pentru că nu Îl cunoaștem cu adevarăt pe Dumnezeu. Tot ceea ce se întâmplă în jurul nostru, favorizează acest tip de viață. De multe ori avem impresia că Îl cunoaștem pe Dumnezeu, dar întâmplările din viața noastră și modul în care reacționăm în situațile critice și de răscruce, ne arată că avem doar o cunoștință teoretică și nu practică a lui Dumnezeu.
                    <br></br><br></br>
                    Pentru a nu ne lăsa păcăliți de cel rău, căutăm ca la fiecare întâlnire să ne aprofundăm în a înțelege vocea lui Dumnezeu. Fie că vorbim despre rugăciune, învățătură, cântare, vrem ca toate să ne învețe cum să trăim doar pentru El. Știm că nu de fiecare dată Îl simțim pe Dumnezeu la fel de aproape, însă vrem să Îl căutăm în sinceritate ori de câte ori avem ocazia.</p>

                    <p>
                    Şi să-L cunosc pe El şi puterea învierii Lui şi părtăşia suferinţelor Lui şi să mă fac asemenea cu moartea Lui<br></br>
                    <b>Filipeni 3:10</b>
                    <br></br><br></br>
                    Şi viaţa veşnică este aceasta: să Te cunoască pe Tine, singurul Dumnezeu adevărat, şi pe Isus Hristos, pe care L-ai trimis Tu<br></br>
                    <b>Ioan 17:3</b>
                    <br></br><br></br>
                    Căutaţi pe Domnul câtă vreme se poate găsi; chemaţi-L câtă vreme este aproape<br></br>
                    <b>Isaia 55:6</b>
                    <br></br><br></br>
                    </p>

                </div>

                <div className="aboutpage-main-slider">

                    <Swiper
                    className="wrapper"
                    loop={true}
                    loopAdditionalSlides={2}
                    watchSlidesProgress={true}
                    watchSlidesVisibility={true}
                    breakpoints={{ 
                        
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },

                        360: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },

                        1150: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },

                        1250: {
                            slidesPerView: 3,
                            spaceBetween: 36
                        }
                    }}
                    >
                    <SwiperSlide><img src={About1} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About2} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About3} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About4} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About5} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About6} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About7} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About8} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About9} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About10} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About11} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About12} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About13} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About14} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About15} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About16} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About17} alt=""/></SwiperSlide>
                    <SwiperSlide><img src={About18} alt=""/></SwiperSlide>
                    </Swiper>

                </div>

                <div className="aboutpage-main-about wrapper">

                    <p>"A fi aproape sau departe de Dumnezeu depinde doar de om, deoarece Dumnezeu este pretutindeni."<br></br>Sfântul Ioan Gură de Aur</p>

                    <p>"Oamenii trebuie cunoscuți pentru a fi iubiți. Dumnezeu trebuie iubit pentru a fi cunoscut."<br></br>Blaise Pascal</p>

                </div>

            </div>

        </div>
    );
}

export default About;